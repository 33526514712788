import bloom1 from './bloom1.png';
import bloom2 from './bloom2.png';
import bloom3 from './bloom3.png';
import bloom4 from './bloom4.png';
import bloom5 from './bloom5.png';
import bloom6 from './bloom6.png';
import bloombergIcon from './bloomberg.png';
import compound1 from './compound1.png';
import compound2 from './compound2.png';
import warrenLogo from './warrenlogo.png';






export const Images = {
  warrenLogo,
  bloombergIcon,
  compound1,
  compound2,
  bloom1,
  bloom2,
  bloom3,
  bloom4,
  bloom5,
  bloom6,
} as const;